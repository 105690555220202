import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';
import Img from 'gatsby-image';
import { cwTheme } from '../theme/theme.js';
import SignUpForm from '../components/SignUpForm';

const PostSection = styled.section`padding: 2rem 0;`;

const PostContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem;
	max-width: 680px;
	margin: 0 auto;
`;

const PostBody = styled.div`
	p {
		font-weight: 400;
	}

	img {
		max-width: 100%;
		margin: 2rem 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${cwTheme.sansSerif};
	}
`;

const MainImage = styled.div`
	width: 100%;
	position: relative;
	padding: 5rem 0 2rem 0;
	color: ${cwTheme.white};
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 8rem 0 6rem;
	}
	text-align: center;
	background-image: url(${(props) => props.image.src});
	background-size: cover;
	background-position: center;
`;

const TeamMembersSection = styled.div`
	margin-top: 5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: row;
	}
`;

const ProfilePicture = styled.div`
	max-width: 250px;
	width: 100%;
	margin: 1rem;
`;

const Bio = styled.div`
	max-width: 680px;
	margin: 1rem;
`;

const Name = styled.h5`margin-top: 0;`;

const Title = styled.h6`
	color: ${cwTheme.secondaryBlue};
	text-decoration: none;
	font-weight: 600;
	margin-top: 0;
`;

const AboutTitle = styled.h2`
	margin-top: 0;
	font-size: 2rem;
`;

export default ({ data }) => {
	const { basePath } = '/';

	const { aboutText, teamMembers, mainImage } = data.contentfulCompany;

	const sources = [
		data.mobileImage.mainImage.fluid,

		{
			...data.desktopImage.mainImage.fluid,
			media: `(min-width: 1200px)`
		}
	];
	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title={'About Us'} description={'About Connect Wellness'} />
				<MainImage image={mainImage.fluid}>
					{/* <Img backgroundColor={cwTheme.lightBlue} fluid={sources} /> */}

					<AboutTitle>About Us</AboutTitle>
				</MainImage>

				<PostSection>
					<PostContainer>
						<PostBody dangerouslySetInnerHTML={{ __html: aboutText.childContentfulRichText.html }} />
					</PostContainer>
				</PostSection>

				{teamMembers.map((person) => (
					<TeamMembersSection>
						<ProfilePicture>
							<Img backgroundColor={cwTheme.lightBlue} sizes={person.profilePhoto.fluid} />
						</ProfilePicture>

						<Bio>
							<Name>{person.name}</Name>
							<Title>{person.title}</Title>
							<div dangerouslySetInnerHTML={{ __html: person.biography.childMarkdownRemark.html }} />
						</Bio>
					</TeamMembersSection>
				))}

				<SignUpForm header={'Receive mindfulness right in your inbox'} />
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query {
		mobileImage: contentfulCompany {
			mainImage {
				fluid(maxWidth: 800, maxHeight: 300, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
		}
		desktopImage: contentfulCompany {
			mainImage {
				fluid(maxWidth: 2400, maxHeight: 500, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
		}
		contentfulCompany {
			mainImage {
				fluid(maxWidth: 2400, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
			aboutText {
				childContentfulRichText {
					html
				}
			}
			teamMembers {
				profilePhoto {
					fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
						src
						...GatsbyContentfulFluid_noBase64
					}
				}
				biography {
					childMarkdownRemark {
						html
					}
				}
				name
				title
			}
		}
	}
`;
